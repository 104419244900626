/**
 * Downloads a file by creating a temporary URL and triggering a download.
 * @param blob - The file data as a Blob object.
 * @param fileName - The desired name of the downloaded file.
 */
export const downloadFile = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
