import {AttachementUploadConfig, AttachmentUpload} from '@hconnect/common/components/attachments'
import {AttachmentFile} from '@hconnect/common/types'
import {CardSectionTitle} from '@hconnect/uikit/src/lib2'
import {Skeleton} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../hooks/permissions/usePermission'

import {useHandleUploadEvent} from './attachment.helper'

const MAX_ALLOWED_ATTACHMENTS = 10
const FILE_MAX_SIZE_IN_MB = 10

interface InlineAttachmentsUploadProps {
  attachments: AttachmentFile[]
  setAttachments: (attachments: AttachmentFile[]) => void
  isDisabled?: boolean
  isLoading?: boolean
}

export const InlineAttachmentsUpload: React.FC<InlineAttachmentsUploadProps> = ({
  attachments,
  setAttachments,
  isDisabled = false,
  isLoading = false
}) => {
  const {t} = useTranslation()
  const canAddDocuments = usePermission('ADD_DOCUMENTS')
  const canDeleteDocuments = usePermission('DELETE_DOCUMENTS')
  const attachmentUploadConfig = useMemo(
    (): AttachementUploadConfig => ({
      allowedFormats: [
        '.pdf',
        '.jpeg',
        '.jpg',
        '.png',
        '.xls',
        '.xlsx',
        '.doc',
        '.docx',
        '.ppt',
        '.pptx'
      ],
      maxAttachements: MAX_ALLOWED_ATTACHMENTS,
      maxFileSizeMB: FILE_MAX_SIZE_IN_MB,
      isAddingEnabled: canAddDocuments && !isDisabled,
      isDeletingEnabled: canDeleteDocuments && !isDisabled
    }),
    [canAddDocuments, canDeleteDocuments, isDisabled]
  )

  const {handleUploadEvent} = useHandleUploadEvent()

  if (isLoading) {
    return <Skeleton variant="rectangular" sx={{width: 1, height: ({spacing}) => spacing(20)}} />
  }

  return (
    <AttachmentUpload
      attachments={attachments}
      setAttachments={setAttachments}
      attachmentsTitle={
        <CardSectionTitle variant="h4">
          {t('common.attachments.label', {
            amount: attachments.length,
            maxAmount: MAX_ALLOWED_ATTACHMENTS
          })}
        </CardSectionTitle>
      }
      uploadEventsCallback={handleUploadEvent}
      config={attachmentUploadConfig}
    />
  )
}
