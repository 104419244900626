import {AxiosResponseHeaders} from 'axios'
import {useCallback} from 'react'

import {downloadFile} from '../helpers/fileHelpers'

export const useFileDownload = () => {
  return useCallback((data: Blob, headers: AxiosResponseHeaders) => {
    const encodedFileName = headers['content-disposition'].split("filename*=UTF-8''")[1]
    // decoding filename
    const fileName = decodeURIComponent(encodedFileName)
    downloadFile(data, fileName)
  }, [])
}
