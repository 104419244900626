import {FileUploadEvent, FileUploadEventType} from '@hconnect/common/components/attachments'
import {useNotification} from '@hconnect/uikit/src/common/notification'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

export const useHandleUploadEvent = () => {
  const {notify} = useNotification()
  const {t} = useTranslation()

  const handleUploadEvent = useCallback(
    (event: FileUploadEvent) => {
      switch (event.type) {
        case FileUploadEventType.FILE_DUPLICATED:
          return notify('info', t('common.attachments.duplicateFiles', {name: event.fileName}))
        case FileUploadEventType.FILE_SIZE_TOO_LARGE:
          return notify('info', t('common.attachments.fileIsTooLarge', {name: event.fileName}))
        case FileUploadEventType.FILE_TYPE_INVALID:
          return notify('info', t('common.attachments.fileTypeIsInvalid', {name: event.fileName}))
        case FileUploadEventType.FILE_LIMIT_REACHED:
          return notify('info', t('common.attachments.fileLimitReached', {name: event.fileName}))
        case FileUploadEventType.FILE_REMOVED:
          return notify(
            'warning',
            <>
              {t('common.attachments.fileIsRemoved', {name: event.fileName})} &nbsp;
              {t('common.attachments.undoRemove')}
            </>,
            {onClickClose: event.undoRemove}
          )
      }
    },
    [notify, t]
  )

  return {handleUploadEvent}
}
