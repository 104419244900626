import {dataTestId} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Close} from '@mui/icons-material'
import {Box, Button, ButtonProps, Dialog, DialogContent, Stack, Typography} from '@mui/material'
import React, {useState, useContext} from 'react'
import {useTranslation} from 'react-i18next'

import {useMediaQueryBreakpoints} from '../../hooks/useMediaQueryBreakpoints'

interface ConfirmDialogProviderState {
  openDialog: (details: ConfirmDialogDetails) => void
  closeDialog: () => void
}
const ConfirmDialogContext = React.createContext<ConfirmDialogProviderState | undefined>(undefined)

export const useConfirmDialog = (): ConfirmDialogProviderState => {
  const context = useContext(ConfirmDialogContext)
  if (!context)
    throw new Error(
      'Cannot use the confirm dialog without having ConfirmDialog provider upper in the component tree'
    )
  return context
}

export interface ConfirmDialogDetails {
  mainAction: {
    color?: ButtonProps['color']
    text: string
    icon?: React.ReactNode
    onAction?: () => void | Promise<void>
  }
  title: string
  subtitle?: string
  description?: string
  additionalContent?: React.ReactNode
  onCancel?: () => void | Promise<void>
  testId?: string
  showCancelButton?: boolean
}

const ConfirmDialogContent: React.FC<
  Omit<ConfirmDialogDetails, 'testId'> & {closeDialog: () => void}
> = ({
  mainAction: {color = 'primary', icon, text, onAction},
  onCancel,
  title,
  subtitle,
  description,
  additionalContent,
  closeDialog,
  showCancelButton = true
}) => {
  const {t} = useTranslation()
  return (
    <>
      <DialogContent sx={{p: 3}}>
        <Stack spacing={2}>
          <Stack>
            <CardTitle {...dataTestId('confirm_dialog_title')} variant="h3" mb={0}>
              {title}
            </CardTitle>
            {subtitle && <Typography variant="caption">{subtitle}</Typography>}
          </Stack>
          {description && (
            <Typography variant="body1" color="textPrimary" align="left">
              {description}
            </Typography>
          )}
          {additionalContent && (
            <Box sx={{mt: 2}} {...dataTestId('confirm_dialog_additional_content')}>
              {additionalContent}
            </Box>
          )}
        </Stack>
        <Box sx={{display: 'flex', mt: 2, justifyContent: 'flex-end'}}>
          {showCancelButton && (
            <Button
              color="secondary"
              {...dataTestId('confirm_dialog_cancel_button')}
              onClick={() => {
                void onCancel?.()
                closeDialog()
              }}
              startIcon={<Close />}
            >
              {t('common.cancel')}
            </Button>
          )}
          <Button
            variant="contained"
            {...dataTestId('confirm_dialog_main_action_button')}
            onClick={() => {
              void onAction?.()
              closeDialog()
            }}
            startIcon={icon}
            color={color}
            sx={{ml: 1}}
          >
            {text}
          </Button>
        </Box>
      </DialogContent>
    </>
  )
}

/**
 * This provider is intended to have a simple interface for having a pop up dialog that forces the user to confirm an action.
 */
export const ConfirmDialogProvider = ({children}: {children: React.ReactNode}) => {
  const [dialogState, setDialogState] = useState<ConfirmDialogDetails | null>(null)

  const {md: isDialogFullScreen} = useMediaQueryBreakpoints()
  const closeDialog = () => setDialogState(null)

  return (
    <ConfirmDialogContext.Provider
      value={{openDialog: setDialogState, closeDialog: () => setDialogState(null)}}
    >
      <Dialog
        open={!!dialogState}
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirm-dialog"
        fullScreen={isDialogFullScreen}
        {...dataTestId(dialogState?.testId ?? 'confirm_dialog')}
      >
        {dialogState && (
          <ConfirmDialogContent
            mainAction={dialogState.mainAction}
            title={dialogState.title}
            subtitle={dialogState.subtitle}
            description={dialogState.description}
            additionalContent={dialogState.additionalContent}
            onCancel={dialogState.onCancel}
            closeDialog={closeDialog}
            showCancelButton={dialogState.showCancelButton}
          />
        )}
      </Dialog>
      {children}
    </ConfirmDialogContext.Provider>
  )
}
